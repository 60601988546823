.pinky-template{
    @import "../bootstrap/2.32/mixins.less";
    @import "../bootstrap/2.32/variables.less";
    @import "../bootstrap/2.32/utilities.less";
    @import "../bootstrap/2.32/component-animations.less";
    @import "../bootstrap/2.32/dropdowns.less";
    @import "../bootstrap/2.32/modals.less";
    @import "../bootstrap/2.32/accordion.less";
    @import "../bootstrap/2.32/popovers.less";
    @import "../bootstrap/2.32/tooltip.less";
    @import "../bootstrap/2.32/sprites.less";
    @import "../bootstrap/2.32/alerts.less";
    @import "../bootstrap/2.32/close.less";
    @import "../bootstrap/2.32/labels-badges.less";
    @import "../bootstrap/2.32/navs.less";
    @import "../bootstrap/2.32/accordion.less";
    @import "../bootstrap/2.32/pagination.less";
}
